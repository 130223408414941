#background-gradient-1{
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    /* animation: gradient 15s ease infinite; */
    overflow: hidden;
}

/* #background-gradient-1:hover{
    background: linear-gradient(-50deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    transition: all;
    animation: normal;
} */

#background-gradient-2{
    background: linear-gradient(-45deg, rgb(182, 244, 146), rgb(51, 139, 147));
    /* animation: gradient 15s ease infinite; */
    overflow: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#pro-badge{
    position: absolute;
    padding: 5px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    right: 6px;
    top: 12px;
    font-size: 11px;
    margin-right: 4px;
    height: -webkit-fit-content;
    background: linear-gradient(48deg, #cfd1eb, #5763fa);
    background-size: 400% 400%;
    animation: Home_AnimationName__JpnWf 8s ease infinite;
}

#reason-badge{
    position: absolute;
    padding: 5px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    right: 6px;
    top: 12px;
    font-size: 11px;
    margin-right: 4px;
    height: -webkit-fit-content;
    background: rgb(239 68 68);
    background-size: 400% 400%;
    padding: 5px 8px 5px 8px;
}

