.auth {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
}
  
.auth__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease-in-out;
    padding: 30px;
    border-radius: 1.5rem;
    /* max-width: 323px; */
    background: white;
}

.auth div {
    margin-top: 7px;
}
  
.auth_logo {
    width: 100px;
    margin-bottom: 20px;
}
  
.baseui-input {
    margin-bottom: 10px;
    width: 100%;
}
  
.baseui-button {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 16px;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
  
.divider__line {
    flex-grow: 1;
    background: #e0e0e0;
    height: 1px;
    border: 0;
    margin: 0 10px;
}
  
.divider__text {
    margin: 0 10px;
}

.element-below-divider {
    margin-top: 20px;
}
  
.toggle-button {
    background-color: transparent;
    border: none;
    color: #007bff;
    padding: 5px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

.title_text{
    margin-bottom: 24px;
    margin-top: 4px;
    font-size: 20px;
    font-weight: bold;
    color: rgb(133, 133, 133);
}

.welcome-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}

.welcome-text {
    color: #5564FF;
    font-size: 21px;
    font-weight: bold; 
    margin-right: 10px;
}

.welcome_logo{
    height: 55px;
    vertical-align: middle;
}