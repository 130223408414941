#background-gradient-1{
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    /* animation: gradient 15s ease infinite; */
    overflow: hidden;
}

/* #background-gradient-1:hover{
    background: linear-gradient(-50deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    transition: all;
    animation: normal;
} */

#background-gradient-2{
    background: linear-gradient(-45deg, rgb(182, 244, 146), rgb(51, 139, 147));
    /* animation: gradient 15s ease infinite; */
    overflow: hidden;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}