.Editor {}

#main-flow{
    /* hacky but works */
    position: unset !important;
}

.flowName {
  position: absolute;
  margin-top: 10px;
  left: 10px;
  position: absolute;
  z-index: 99999;
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  /* font-family: "JetBrains Mono",monospace; */
  width: 250px;
  height: 60px;
  justify-content: left;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  /* position: relative; */
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 18px;
  border: none;
  outline: none;
  width: 100%;
}

.date {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.flowName:focus-within {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

/* .playButton{
    position: absolute;
    z-index: 99999;
} */
/* CSS */

.closeButton{
  z-index: 9999999;
  position: absolute;
  right: 0px;
  margin-right: 28px;
  margin-top: 28px;
}

.playButton {
  right: 10px;
  margin-top: 10px;
  position: absolute;
  z-index: 99999;
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-weight: 800;
  /* font-family: "JetBrains Mono",monospace; */
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  /* position: relative; */
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.playButton:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.playButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.playButton:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}