body{
    background: #fafafa;
    /* background: #edf1f7; */
}

.integration-connector{
    text-align: center;
    /* margin-top: 10%; */
}

/* Container for all integration button */
.integrations-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
  justify-content: start; /* Aligns items to the start of the container */
  align-items: center; /* Aligns items vertically */
  margin-right: 1rem;
  margin-bottom: 1rem;
  gap: 16px 4px; /* Adds space between the tags and buttons */
}

/* Style for individual integration tags */
.integration-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  border-radius: 1rem;
  padding: 0 1rem;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  /* margin-right: 1rem; */
}

/* Style for the remove button within each integration tag */
.remove-integration {
  margin-left: 8px;
  cursor: pointer;
  color: #999; /* A neutral color for the remove icon */
}

/* Optionally, change color on hover for visual feedback */
.remove-integration:hover {
  color: #333; /* Darker color on hover */
}

/* Style for the add integration button */
/* .add-integration-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  height: 3.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
  border: 2px dashed #e2e8f0;
  cursor: pointer;
  transition: border-color 0.3s ease;
} */

/* Hover state for the add integration button */
.add-integration-button:hover {
  transform: scale(1.05); /* Changes the dashed border color on hover */
}