/* Assuming the button's parent container is body. If not, replace 'body' with your container's class or ID */
/* This overridden the whole body of the old app */
/* body {
    margin: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */
:root {
  --primary-color: 111, 76, 255;
}

.force-black {
  color: black !important;
}

#new-badge{
  position: absolute;
  padding: 5px;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  right: 6px;
  top: 12px;
  font-size: 11px;
  margin-right: 4px;
  height: -webkit-fit-content;
  background: linear-gradient(48deg, #48e4c2, #1dc12d);
  background-size: 400% 400%;
  animation: Home_AnimationName__JpnWf 8s ease infinite;
}
.connect-btn {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 32px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.connect-btn-gradient-border {
  color: rgb(0, 0, 0);
  border: 3px double transparent;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.connect-btn-glow:hover {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

body{
    background: #fafafa;
    /* background: #edf1f7; */
}

.integration-connector{
  text-align: center;
  /* margin-top: 10%; */
}

.integrations-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
  justify-content: start; /* Aligns items to the start of the container */
  align-items: center; /* Aligns items vertically */
  margin-right: 1rem;
  margin-bottom: 1rem;
  gap: 16px 4px; /* Adds space between the tags and buttons */
}

.integration-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 10px 0 13px;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  /* margin-right: 1rem; */
}

.remove-integration {
  margin-top: -2px; 
  margin-left: 8px;
  cursor: pointer;
  color: #999; /* A neutral color for the remove icon */
}

.remove-integration:hover {
  color: #333; /* Darker color on hover */
}

.add-integration-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 8rem; */
  height: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 10px;
  border: 2px dashed #e2e8f0;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.add-integration-button:hover {
  transform: scale(1.05);
}

.feature-container {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    /* height: 100vh; */
  }
  
  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .card.checked {
    background: #e3f2fd; /* Change the background color when checked */
  }
  
  .card-content {
    margin-bottom: 20px;
  }
  
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
    border: 1px solid #2196F3;
  }
  
  /* Style the checkmark inside the checkbox */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }


  .email-item {
    transition: height 0.3s ease; /* Define your preferred transition properties */
    overflow: hidden;
    height: 24px; /* Set initial height */
  }
  
  .email-item.expanded {
    height: auto; /* Auto height for expanded state */
  }

  .fade-in-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in-animation:hover,
  .fade-in-animation:not(:hover) {
    opacity: 1;
  }

/* For the main content */
.main-content {
  position: relative; /* Position set to work with z-index */
  z-index: 100; /* Lower than NavBar and Modal */
}