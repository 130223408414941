/* SingleEmailView.css */
.background-animate {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 6s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* @keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
} */

div[tabindex="-1"][aria-modal="true"] {
    /* Apply base styles to the modal content container */
    width: 100%; /* Full width by default */
    /* max-height: 95vh;  */
    /* padding: 16px; 
    border: 12px solid transparent; 
    margin: 16px auto;  */
    border-radius: 8px; /* Assuming you want rounded edges */
    box-sizing: border-box; /* Include padding and border in the width */
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Stack children vertically */
    overflow: hidden; /* Hide overflow */
  }
  
  /* Ensure the content area is scrollable */
  .email-content {
    overflow-y: auto; /* Make content area scrollable */
    /* margin-bottom: 16px;  */
    flex-grow: 1; /* Let the content area grow to fill available space */
  }

  .email-iframe {
    width: 100%; /* Takes the full width of the container */
    height: 500px; /* Fixed height, or you can use a percentage */
    border: none; /* Removes the border */
    display: block; /* Ensures the iframe is a block element to take full width */
    margin: auto; /* Centers the iframe horizontally */
    border-radius: 8px; /* Optional: Keeps the rounded corners if desired */
    overflow-y: auto; /* Adds scroll to the iframe if content overflows */
  }
  
  /* Small screens (typically below 1024px, you can adjust as needed) */
  @media (max-width: 1023px) {
    div[tabindex="-1"][aria-modal="true"] {
      width: 90%; /* Occupy 90% of the screen width */
      /* margin: 2.5vh auto;  */
    }
  }
  
  @media (min-width: 1024px) {
    div[tabindex="-1"][aria-modal="true"] {
      /* Apply styles for large screens */
      width: 66.6667%; /* Assuming 2/3 width as before */
      /* Other styles remain the same */
    }
  }
  
  @media (min-width: 1280px) {
    div[tabindex="-1"][aria-modal="true"] {
      /* Apply styles for extra-large screens to match the menu width */
      width: 700px; /* Fixed width as per your specifications */
      /* Adjust margin if needed to ensure the modal is centered */
      margin-left: calc((100% - 700px) / 2);
      margin-right: calc((100% - 700px) / 2);
    }
  }
  