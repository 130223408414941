.choosePlan {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
}

.choosePlan__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease-in-out;
    padding: 30px;
    border-radius: 1.5rem;
    /* max-width: 323px; */
    background: white;
}

.choosePlan__titleText {
    margin-bottom: 24px;
    font-size: 25px;
    font-weight: bold;
    color: rgb(133, 133, 133);
}

.choosePlan__logo {
    height: 55px;
    margin-bottom: 20px;
}

.choosePlan__card {
    margin-bottom: 20px;
}

.baseui-button {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 16px;
}

/* Buttons */

.btn,
.btn-sm{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  font-size: 0.875rem;
  line-height: 1.5715;
  font-weight: 500;
  letter-spacing: 0em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-sm{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
