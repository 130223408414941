.border-dance {
    position: relative;
    border: 0;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-dance:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  border: 2px dashed limegreen;
  animation: rotate 20s linear infinite;
}
  
@keyframes rotate {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}