.qanda-node {
    /* height: 100px; */
    max-width: 250px;
    border: 2px solid #FFAFCC;
    border-left: 8px solid #FFAFCC;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }
  
.qanda-node label {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 10px;
}

.subtitle{
    font-size: 7px;
    color: gray;
}

/* .promptingoption{
    font-size: 8px;
} */

.add-btn{
  font-size: 7px;
  font-weight: 600;
}