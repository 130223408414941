.profile-dropdown-container {
    position: relative;
    display: inline-block;
}

.profile-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 10px;
    margin-right: 20px;
    padding: 8px 16px;
}
  
.profile-section:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.profile-section.active {
    background-color: rgba(0, 0, 0, 0.04);
}

.profile-section .ml-3:hover {
  background-color: transparent;
}

.dropdown-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 105%;
    width: calc(100% - 20px);
    background-color: rgba(240, 240, 240);
    border: none;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 100;
    box-sizing: border-box;
}
  
.dropdown-menu button {
    display: block;
    width: calc(100%);
    text-align: left;
    background: none;
    border: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    box-sizing: border-box;
}

.dropdown-menu button:hover,
.dropdown-menu button:active { /* Apply the same style for hover and active states */
    background-color: rgba(255, 255, 255, 0.5);
}

.dropdown-menu button:disabled {
    color: #9ca3af; /* Gray color for disabled text */
    background-color: transparent; /* Ensure no background color on hover */
}
  
.dropdown-menu button:disabled:hover {
    background-color: transparent; /* No hover effect */
}
  
.mainnav{
    z-index: 1000;
}

.logo-img{
    height: 30px;
    padding-left: 14px;
}