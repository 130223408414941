.questions-node {
    /* height: 100px; */
    max-width: 250px;
    border: 2px solid #FCA310;
    border-left: 8px solid #FCA310;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }
  
.questions-node label {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 10px;
}

.subtitle{
    font-size: 7px;
    color: gray;
}

.question-input{
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-top: 7px;
    font-size: 10px;
    padding-left: 3px;
    width: 100%;
}

.add-btn{
  font-size: 7px;
  font-weight: 600;
}

/* .add-btn{
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.add-btn:disabled {
  cursor: default;
}

.add-btn:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
} */