.container {
    /* width: calc(1777px * 0.05);
    height: calc(2000px * 0.05); */
    position: relative;
  }
  
  .frame {
    width: 1706px;
    height: 1120px;
    transform: scale(0.13);
    transform-origin: 0 0;
    position: absolute;
    left: 0;
    margin: 4px;
    top: 0;
    border-radius: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

.browseweb-node {
    height: 212px;
    max-width: 250px;
    border: 2px solid #895737;
    border-left: 8px solid #895737;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }
  
.browseweb-node label {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 10px;
}

.browseweb-input{
  width: 220px;
  margin: 4px;
  font-size: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 6px;
  padding: 4px;
}

.subtitle{
    font-size: 7px;
    color: gray;
}

.fileuploadoption{
    font-size: 8px;
}
/* .question-input{
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-top: 7px;
    font-size: 10px;
    padding-left: 3px;
    width: 100%;
} */

.add-btn{
  font-size: 7px;
  font-weight: 600;
}