.invite {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
}
.invite__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease-in-out;
    padding: 30px;
    border-radius: 1.5rem;
    max-width: 323px;
    background: white;
}
.info-text{
    font-size: 14px;
}
.title_text{
    margin-bottom: 24px;
    margin-top: 4px;
    font-size: 20px;
    font-weight: bold;
    color: rgb(133, 133, 133);
}
.welcome-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.welcome-text {
    color: #5564FF;
    font-size: 21px;
    font-weight: bold; 
    margin-right: 10px;
}
.welcome_logo{
    height: 55px;
    vertical-align: middle;
}