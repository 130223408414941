.prompting-node {
    /* height: 100px; */
    max-width: 250px;
    border: 2px solid #ff0072;
    border-left: 8px solid #ff0072;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }
  
.prompting-node label {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 10px;
}

.expanding-textarea {
    resize: none;
    overflow: hidden;
    min-height: 80px;    
    height: auto;
    transition: height 0.2s;
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-top: 7px;
    width: 100%;
    font-size: 8px;
    padding: 3px;
  }
  
.expanding-textarea:focus {
    height: fit-content;
}
.subtitle{
    font-size: 7px;
    color: gray;
}

.promptingoption{
    font-size: 8px;
}
/* .question-input{
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-top: 7px;
    font-size: 10px;
    padding-left: 3px;
    width: 100%;
} */

.add-btn{
  font-size: 7px;
  font-weight: 600;
}