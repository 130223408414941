.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }
  
  .dndflow .dndnode {
    height: 40px;
    padding-left: 10px;
    border: 2px solid #1a192b;
    border-radius: 6px;
    margin-bottom: 10px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    cursor: grab;
    border-left: 8px solid;
    
    /* height: 40px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 6px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab; */
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  }
  
  .dndflow .dndnode.output {
    border-color: #ff0072;
  }
  
  .dndflow .dndnode.questions{
    border-color: #FCA310;
  }

  .dndflow .dndnode.qanda{
    border-color: #FFAFCC;
  }

  .dndflow .dndnode.summary{
    border-color: #5E35B1;
  }

  .dndflow .dndnode.createpdf{
    border-color: #2B9D8F;
  }

  .dndflow .dndnode.browseweb{
    border-color: #895737;
  }

  .dndflow .dndnode.readfile{
    border-color: #E63947;
  }

  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 250px;
      z-index: 999999999;
    }
  }
  