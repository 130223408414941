.playButton {
    bottom: 43px;
    right: 43px;
    margin-top: 10px;
    position: absolute;
    z-index: 99999;
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-weight: 800;
    /* font-family: "JetBrains Mono",monospace; */
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    /* position: relative; */
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
  }
  
  .playButton:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  .playButton:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .playButton:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }

.Home{

}

.ProBadge{
    position: absolute;
    /* background-color: aquamarine; */
    padding: 5px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    right: -15px;
    top: -7px;
    font-size: 11px;
    margin-right: 4px;
    height: -webkit-fit-content;
    background: linear-gradient(48deg, #5564FF, #ffb3d1);
    background-size: 400% 400%;
    animation: Home_AnimationName__JpnWf 30s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.ProgressbarText{
    text-align: center;
    font-size: 11px;
}

.Progressbar{
    position: absolute;
    bottom: 36px;
    width: 100%;
}

.SummaryText{
    /* only fucking work on Chrome */
    -webkit-user-modify: read-write-plaintext-only;
    overflow: scroll;
    padding: 0px;  
}

.SummaryText h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

.CardView{
    width: 100%;
    height: 100%;
    /* background-color: aliceblue; */
    display: flex;
    margin: 25px;
    border-radius: 17px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top:100px;
    border: solid 1px color(srgb 0.8862 0.8863 0.8864);
}

.NavView{
    position: absolute;
    display: flex;
    top: 26px;
    right: 25px;
    border-radius: 17px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 60px;
    padding: 10px;
}

.LogoView{
    position: absolute;
    display: flex;
    top: 26px;
    left: 25px;
    border-radius: 17px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 58px;
    padding: 10px;
}

.LeftView{
    width: 25%;
    border-right: 1px solid #e4e4e4;
    position: relative;
}

.LeftViewItem{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    height: 60px;
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
}

.ButtonContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    overflow-y: scroll;
}

.UploadButton{
    text-align: center;
    margin-top: 15px;
}

.imageThumbnail{
    height:40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.imageThumbnailDiv{
    width: 80px;
    float: left;
    /* display: inline-flex; */
    display:table-cell; 
    vertical-align:middle; 
    text-align:center
}

.inputChat{
    /* position: absolute; */
    bottom: 5px;
    width: 100%;
}

.inputField{
    width: 83%;
    float: inline-start;
}

.LogoutButton{
    bottom: 10px;
    position: absolute;
    width: 100%;
    text-align: center;
}

.pitchFileName{
    font-size: 18px;
    margin-top: 7px;
    margin-left: 87px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DataItems {
    width: 100%;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.DeleteButton{
    float: right;
    margin-top: -41px;
    margin-right: -9px;
}