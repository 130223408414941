/* TESTING */
.CardFloat{
    position: absolute;
    top: 64px;
    right: -100%;
    width: 100%;
    height: 100%;
    transition: right 0.5s; 
}

.CardFloat.slide-in {
    right: 0; /* Slide the card into view */
}